<template>
  <v-dialog
    v-model="modal"
    width="1800"
    @click:outside="fechaModal()"
    @keydown.esc="fechaModal()"
  >
    <v-card>
      <v-card-title>
        <h3 v-if="atestado.id == null">Adicionar Atestado</h3>
        <h3 v-else>Atestado {{ atestado.dataAtestado }}</h3>
        <v-icon @click="fechaModal()">fa-times</v-icon>
      </v-card-title>

      <v-card-text v-if="atestado.id != null">
        <v-container :class="$vuetify.breakpoint.lgAndUp ? 'px-8' : ''">
          <!-- Dados do Atestado -->
          <v-row class="titulo-atestado">
            <v-col>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Dados do Atestado
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <div class="dados-atestado">
                Nome do médico
                <div class="fonte-azul">{{ atestado.nomeMedico }}</div>
              </div>
            </v-col>
            <v-col cols="6" md="4">
              <div class="dados-atestado">
                CRM
                <div class="fonte-azul">{{ atestado.crm }}</div>
              </div>
            </v-col>
            <v-col cols="6" md="4">
              <div class="dados-atestado">
                CID
                <div class="fonte-azul">{{ atestado.cid }}</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="4">
              <div class="dados-atestado">
                Quantidade de dias:
                <div class="fonte-azul">{{ atestado.qtdDias }}</div>
              </div>
            </v-col>
            <v-col cols="6" md="4">
              <div class="dados-atestado">
                Data do atestado:
                <div class="fonte-azul">{{ atestado.dataAtestado }}</div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="dados-atestado">
                Comentário
                <div class="fonte-azul">{{ atestado.comentarios }}</div>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-5" />

          <!-- Estado do Atestado -->
          <v-row class="titulo-atestado">
            <v-col>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Estado do Atestado
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <div class="dados-atestado">
                Status:
                <div class="fonte-azul">{{ atestado.status.descricao }}</div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="dados-atestado">
                Data de Atualização:
                <div class="fonte-azul">
                  {{ dataAtualizacao(atestado.dataAtualizacao) }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="dados-atestado">
                Gestor Ciente:
                <div class="fonte-azul">
                  {{ gestorCliente(atestado.gestorCiente) }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-5" />

          <!-- Documentos Anexados -->
          <v-row class="titulo-atestado">
            <v-col>
              <h4>
                <v-icon>fas fa-caret-right</v-icon>
                Documentos Anexados
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="documentos"
                :items-per-page="5"
                :hide-default-footer="true"
                :no-data-text="tabelaVazia()"
              >
                <template v-slot:item.acao="{ item }">
                  <div class="acoes-tabela">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="visualizarDocumento(item.id)"
                          class="icone-acao"
                        >
                          fa-solid fa-download
                        </v-icon>
                      </template>
                      <span>Baixar</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-divider class="mt-8" />
        </v-container>
      </v-card-text>
      <v-card-text v-else>
        <v-container :class="$vuetify.breakpoint.lgAndUp ? 'px-8' : ''">
          <v-form ref="formContrato">
            <!-- Opções Formulário -->
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  :rules="rules.nomeMedico"
                  required
                  v-model="modeloAtestado.nomeMedico"
                  label="Nome do Médico:"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="dataAtestado"
                  required
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="rules.obrigatorio"
                      v-model="modeloAtestado.dataAtestado"
                      outlined
                      dense
                      label="Data do Atestado:"
                      placeholder="DD/MM/AAAA"
                      v-on="on"
                      hide-details="auto"
                      v-bind="attrs"
                      maxLength="10"
                    >
                      <template slot="append">
                        <v-icon color="#278b97">fas fa-calendar-days</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    color="#278b97"
                    v-model="filtroDataAtestado"
                    required
                    :allowed-dates="allowedDates"
                    @input="
                      modeloAtestado.dataAtestado =
                        formatDate(filtroDataAtestado)
                    "
                    :rules="[(v) => !!v || 'O campo Data é obrigatório.']"
                    locale="pt-br"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="3">
                <v-text-field
                  :rules="rules.crm"
                  v-model="modeloAtestado.crm"
                  label="CRM:"
                  type="number"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-select
                  :rules="rules.obrigatorio"
                  v-model="modeloAtestado.uf"
                  label="UF do CRM:"
                  outlined
                  dense
                  :items="ufs"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  :rules="rules.campoObrigatorio"
                  v-model="modeloAtestado.qtdDias"
                  type="number"
                  label="Quantidade de Dias:"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  :rules="rules.cid"
                  v-model="modeloAtestado.cid"
                  label="CID:"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Comentário (opcional):"
                  v-model="modeloAtestado.comentario"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- Aviso -->
            <v-row>
              <v-col>
                <Aviso
                  message="Preencha uma data do atestado válida para liberar o anexo de documentos."
                  bg_hex="#D9D9D9"
                />
              </v-col>
            </v-row>
            <!-- Anexos -->
            <v-row>
              <v-col cols="12" md="6">
                <v-file-input
                  :rules="rules.campoObrigatorio"
                  v-model="modeloAtestado.atestado"
                  outlined
                  dense
                  placeholder=" Escolha o anexo..."
                  accept="application/pdf"
                  hide-details
                  :prepend-icon="null"
                >
                  <template slot="label">
                    <span>Anexar Atestado</span>
                  </template>
                  <template slot="append">
                    <v-icon>fa-solid fa-paperclip</v-icon>
                  </template>
                </v-file-input>
                <p class="mensagem-alerta">Permitido somente arquivos PDF</p>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  color="#278b97"
                  v-model="modeloAtestado.homologacao"
                  outlined
                  dense
                  placeholder="Escolha o anexo..."
                  accept="application/pdf"
                  hide-details
                  :prepend-icon="null"
                >
                  <template slot="label">
                    <span>Homologação</span>
                  </template>
                  <template slot="append">
                    <v-icon>fa-solid fa-paperclip</v-icon>
                  </template>
                </v-file-input>
                <p class="mensagem-alerta">
                  <v-icon>fa-solid fa-triangle-exclamation</v-icon>
                  Homologação é obrigatória para todos. Saiba mais em
                  <a
                    href="https://nossahepta.com.br/IntranetHepta/#/detalhe-academia?id=503&pesquisa=false&artigo=true"
                    target="_blank"
                  >
                    Atestados Médicos
                  </a>
                </p>
              </v-col>
            </v-row>
            <v-divider insert class="mt-5" />
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="botoes-modal">
        <v-row v-if="atestado.id != null">
          <v-col cols="12" md="4">
            <v-btn @click="fechaModal()" class="botao-cancelar w-100"
              >Sair</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="4">
            <v-btn @click="fechaModal()" class="botao-cancelar w-100">
              <v-icon class="mr-2">fa-solid fa-xmark</v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn @click="salvarAtestado()" class="botao-principal w-100">
              <v-icon class="mr-2">fa-solid fa-arrow-up-from-bracket</v-icon>
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/services/Api.js";
import Aviso from "@/components/Aviso.vue";

export default {
  name: "modal-atestados",
  components: { Aviso },
  props: {
    atestado: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    rules: {
      nomeMedico: [
        (v) => v.length >= 5 || "Caracteres insuficientes!",
        (v) => !!v || "Campo obrigatório",
      ],
      crm: [
        (v) => v.length >= 4 || "Caracteres insuficientes!",
        (v) => !!v || "Campo obrigatório",
      ],
      cid: [
        (v) => v.length >= 2 || "Caracteres insuficientes!",
        (v) => !!v || "Campo obrigatório",
      ],
      campoObrigatorio: [(v) => !!v || "Campo obrigatório"],
      obrigatorio: [(value) => !!value || "Campo obrigatório"],
    },
    modal: false,
    download: false,
    arquivoConteudo: null,
    documentos: [],
    arquivo: {},
    headers: [
      { text: "Nome do arquivo", value: "nomeTitulo" },
      { text: "Documento", value: "codTipoDocumento.nomeDominio" },
      { text: "Ação", value: "acao", align: "center", sortable: false },
    ],
    ufs: [],
    dataAtestado: false,
    filtroDataAtestado: null,
    filtroDataAtestadoFormatado: null,
    modeloAtestado: {
      nomeMedico: "",
      crm: "",
      uf: null,
      qtdDias: 0,
      cid: "",
      dataAtestado: null,
      atestado: null,
      homologacao: null,
      comentario: null,
      bkpAtestado: null,
    },
    /** Dominio */
    dominio: {
      atestado: 923,
      homologacao: 924,
      tipoPdf: 713,
      tipoPng: 715,
      tipoJpg: 714,
    },
  }),
  watch: {
    value(e) {
      this.modal = e;
      if (this.atestado.id != null) {
        this.buscarDocumentosAtestado();
      }
    },
  },
  methods: {
    fechaModal: function () {
      this.$emit("fecha-modal");
      this.limparVariaveis();
    },
    limparVariaveis() {
      this.modeloAtestado = {
        nomeMedico: "",
        crm: "",
        uf: null,
        qtdDias: 0,
        cid: "",
        dataAtestado: null,
        atestado: null,
        homologacao: null,
        comentario: null,
        bkpAtestado: null,
      };
      this.filtroDataAtestadoFormatado = null;
    },
    buscarDocumentosAtestado() {
      const vm = this;
      api
        .get(`atestados/documento/` + vm.atestado.id)
        .then((res) => {
          vm.documentos = res.data;
        })
        .catch((e) => {
          console.error(e.response.data);
        });
    },
    visualizarDocumento(idDocumento) {
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_URL +
        `atestados/documento/visualizar/` +
        idDocumento;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    },
    formatDate(e) {
      if (!e) return null;
      const [year, month, day] = e.split("-");
      this.dataAtestado = false;
      return `${day}/${month}/${year}`;
    },
    formatDateSQL(e) {
      if (!e) return null;
      const [day, month, year] = e.split("/");
      return `${year}-${month}-${day}`;
    },
    salvarAtestado() {
      const vm = this;

      if (vm.$refs.formContrato.validate()) {
        let atestado = null;
        let homologacao = null;

        if (vm.modeloAtestado.atestado) {
          atestado = vm.montarFormData(
            vm.dominio.atestado,
            vm.modeloAtestado.atestado
          );
        }
        if (vm.modeloAtestado.homologacao) {
          homologacao = vm.montarFormData(
            vm.dominio.homologacao,
            vm.modeloAtestado.homologacao
          );
        }

        //emit pro Atestado.vue salvar o atestado e os documentos do atestado e homologação!
        vm.$emit("salvar", vm.montarAtestado(), atestado, homologacao);
        vm.fechaModal();
      }
    },
    montarAtestado() {
      const vm = this;
      const obj = {
        nomeMedico: vm.modeloAtestado.nomeMedico,
        dataAtestado: vm.formatDateSQL(vm.modeloAtestado.dataAtestado),
        qtdDias: vm.modeloAtestado.qtdDias,
        crm: vm.modeloAtestado.crm + "/" + vm.modeloAtestado.uf,
        cid: vm.modeloAtestado.cid,
        comentarios: vm.modeloAtestado.comentario
          ? vm.modeloAtestado.comentario
          : null,
        documentos: [],
      };
      return obj;
    },
    montarFormData(dominio, vModel) {
      const vm = this;
      let anexoFormData = new FormData();
      anexoFormData.append("tipoDocumento", dominio);
      anexoFormData.append("nomeTitulo", vModel.name);
      anexoFormData.append("binDocumento", vModel);
      anexoFormData.append(
        "formatoDocumento",
        vm.formatoDocumento(vModel.type)
      );
      return anexoFormData;
    },
    gestorCliente(e) {
      if (e) {
        return "Sim";
      } else {
        return "Não";
      }
    },
    formatoDocumento(vModelType) {
      const vm = this;
      switch (vModelType) {
        case "application/pdf":
          return vm.dominio.tipoPdf;
          break;
        case "image/jpeg":
          return vm.dominio.tipoJpg;
          break;
        case "image/png":
          return vm.dominio.tipoPng;
          break;
      }
    },
    dataAtualizacao(e) {
      if (e) {
        return e;
      } else {
        return "Sem data de atualização!";
      }
    },
    tabelaVazia() {
      return "Nenhum documento encontrado!";
    },
    buscarUfs() {
      const vm = this;
      api.get("v2/dominio/uf").then((response) => {
        response.data.forEach((e) => {
          vm.ufs.push(e.nomeDominio);
        });
      });
    },
    contarDiasUteis(dataInicio, dataFim) {
      let count = 0;
      let dataAtual = new Date(dataInicio);

      while (dataAtual <= dataFim) {
        // Ignorar fins de semana (0 = domingo, 6 = sábado)
        if (dataAtual.getDay() !== 0 && dataAtual.getDay() !== 6) count++;

        dataAtual.setDate(dataAtual.getDate() + 1);
      }

      return count;
    },
    dataValida48HrsUteis(dataString) {
      if (!dataString) return false;

      const dataSelecionada = new Date(dataString);
      const hoje = new Date();

      // Calcula diferença em dias úteis
      const diasUteis = this.contarDiasUteis(dataSelecionada, hoje);

      return diasUteis <= 2;
    },
  },
  async mounted() {
    await this.buscarUfs();
  },
  computed: {
    allowedDates() {
      return (date) => this.dataValida48HrsUteis(date);
    },
  },
};
</script>

<style scoped>
.titulo-atestado h4 {
  display: flex;
  align-items: center;
  color: #e7b814;
  font-weight: 700;
}

.titulo-atestado h4 .v-icon {
  margin-right: 1rem;
  color: #e7b814;
}

.dados-atestado {
  border-left: 3px solid #008693;
  padding-left: 0.75rem;
  font-size: 1rem;
  text-align: left;
  color: #5d5d5d;
}

.fonte-azul {
  color: #008693;
}

.mensagem-alerta {
  color: #5d5d5d9c !important;
  font-size: 0.75rem;
}

.mensagem-alerta .v-icon {
  color: #929292;
  font-size: 1rem;
  padding-right: 0.25rem;
}

.botoes-modal .row {
  display: flex;
  justify-content: center;
}
</style>
